import React from "react";
import { RainbowMenu } from "../components/RainbowMenu";
import { Sidebar } from "../components/Sidebar";
import { StaticImage } from "gatsby-plugin-image";
import { LogOpen } from "../components/LogOpen";

import "./styles.scss"

export default function PostsPage() {

  return <div>
    <LogOpen page="team" />
    <RainbowMenu active="Team" relativePath="../"></RainbowMenu>

        <div className="columns">
          <div className="column is-3">
            <Sidebar></Sidebar>
          </div>
          <div className="column is-9">
            <h2 className="title is-4">Das SMAG Team</h2>
            <p>Wir sind das SMAG-Team. Unser Ziel ist es SMAG zu einer Gruppe zu
            machen bei der ihr euch auch weiterhin wohl fühlt und euch mit
            Vorschlägen, Wünschen und Ideen einbringen könnt.</p>

            <p>Du hast Fragen zu uns oder unseren Aktionen? Dann kontaktier uns
            einfach über <a href="mailto:smag@fliederlich.de">smag@fliederlich.de</a>. Wir
            freuen uns über jeden, der Interesse an unserer Gruppe hat.</p>

            <div className="columns mt-2 is-multiline">
              <div className="column is-4">
                <div className="box has-text-centered has-background-light">
                  <StaticImage src="../images/marco.png" alt="Marco" />
                  <div className="title is-4">Marco</div>
                </div>
              </div>
              <div className="column is-4">
                <div className="box has-text-centered has-background-light">
                  <StaticImage src="../images/markus.jpg" alt="Markus" />
                  <div className="title is-4">
                    Markus
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="box has-text-centered has-background-light">
                  <StaticImage src="../images/uli.jpg" alt="Uli" />
                  <div className="title is-4">Uli</div>
                </div>
              </div>
            </div>
          </div>
        </div>


  </div>;
}